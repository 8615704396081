import { Link } from 'react-router-dom';
import { SocialButtonInstagram } from '../../../../atoms/socialButtonInstagram/SocialButtonInstagram';
import { SocialButtonLinkedin } from '../../../../atoms/socialButtonLinkedin/SocialButtonLinkedin';
import { SocialButtonTwitter } from '../../../../atoms/socialButtonTwitter/SocialButtonTwitter';
import { PrivacyLink } from '../../../../atoms/privacyLink/PrivacyLink';
import { CookiesLink } from '../../../../atoms/cookiesLink/CookiesLink';
import { JamenetEmail } from '../../../../atoms/jamenetEmail/JamenetEmail';
import { JamenetAddress } from '../../../../atoms/jamenetAddress/JamenetAddress';
import { JamenetDots } from '../../../../atoms/jamenetDots/JamenetDots';
import { JamenetPhone } from '../../../../atoms/jamenetPhone/JamenetPhone';
import { INDEX } from '../../../../../routing/routes';
import { t } from '../../../../../constants/transations';
import './ErrorFooterView.css';

export const ErrorFooterView = (props) => (
    <footer className="footer footer-bg-error">
        <div className="container">
            <div className="columns error-footer-block1">
                <div className="column is-1" />
                <div className="column is-10">
                    <div className="columns">
                        <div className="column is-6 error-footer-top-content">
                            <p className="error-404-white">
                                Ba al duzu zerbait garrantzitsua kontatzeko?//
                            </p>
                            <p className="error-404-gray mt-2">
                                ¿Tienes algo importante que decirnos?
                            </p>
                        </div>
                        <div className="column is-6 error-footer-top-content">
                            <p>
                                <JamenetPhone className="error-404-contact-top" />
                            </p>
                            <JamenetEmail className="error-404-contact-bottom" />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column" />
                        <div className="column is-center-button">
                            <Link
                                className="button error-footer-button is-centered"
                                to={INDEX}
                            >
                                {t.back_home}
                            </Link>
                        </div>
                        <div className="column" />
                    </div>
                </div>
                <div className="column is-1" />
            </div>
        </div>
        <div className="columns footer-div mt-2">
            <div className="column is-1" />
            <div className="column footer-box">
                <div id="a" className="footer-link">
                    <CookiesLink
                        className="is-footer-text has-text-left mt-6"
                        lang={props.lang}
                    />
                    <PrivacyLink
                        lang={props.lang}
                        className="is-footer-text has-text-left mt-2"
                    />
                </div>
                <div id="b">
                    <div className="icons-position">
                        <SocialButtonInstagram
                            lang={props.lang}
                            white
                            className="error-footer-icons error-footer-icons-desktop"
                        />
                        <SocialButtonLinkedin
                            lang={props.lang}
                            white
                            className="error-footer-icons ml-4"
                        />
                        <SocialButtonTwitter
                            lang={props.lang}
                            white
                            className="error-footer-icons ml-4"
                        />
                    </div>
                    <JamenetAddress
                        lang={props.lang}
                        className="is-footer-text has-text-centered mt-2"
                    />
                    <JamenetEmail className="is-footer-text has-text-centered mt-2" />
                </div>
                <div id="c" className="footer-link">
                    <p className="is-footer-text has-text-right mt-6">
                        {t.jamenet_copyrigth}
                    </p>
                    <JamenetDots className="footer-brand-img mt-2" />
                </div>
            </div>
            <div className="column is-1" />
        </div>
    </footer>
);
