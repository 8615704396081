import { createSelector } from '@reduxjs/toolkit';

export const homeSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            buttonInfo: mainState && mainState.homePayload,
            backgroundImage:
                mainState &&
                mainState.homePayload &&
                mainState.homePayload &&
                mainState.homePayload.backgroundImage,
            description:
                mainState &&
                mainState.homePayload &&
                mainState.homePayload &&
                mainState.homePayload.description,
            button:
                mainState &&
                mainState.homePayload &&
                mainState.homePayload &&
                mainState.homePayload.button,
            icons:
                mainState &&
                mainState.homePayload &&
                mainState.homePayload &&
                mainState.homePayload.icons,
            logo:
                mainState &&
                mainState.homePayload &&
                mainState.homePayload &&
                mainState.homePayload.logo,
            lang: mainState && mainState.lang,
            isLinkActive: mainState && mainState.isActiveIcon,
            menuModal: mainState && mainState.menuModal,
        };
    }
);
