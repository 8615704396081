import React from 'react';

export const Error500icon = ({ ...rest }) => (
    <svg
        viewBox="0 0 308 182"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        {...rest}
    >
        <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="UI-desk-error-500"
                transform="translate(-1118.000000, -296.000000)"
                fill="#000000"
            >
                <g
                    id="icon-jmnt-500"
                    transform="translate(1272.000000, 387.000000) scale(-1, -1) rotate(-90.000000) translate(-1272.000000, -387.000000) translate(1181.000000, 233.000000)"
                >
                    <path
                        d="M80.295742,264.17753 L80.2954664,264.177732 L152.811603,211.212678 C156.147096,208.77585 160.82555,209.505531 163.260634,212.841937 L168.67748,220.263056 C171.113525,223.598182 170.38419,228.276602 167.049126,230.71202 L94.53299,283.677074 C91.1974966,286.113901 86.5190423,285.384221 84.0839591,282.047815 L78.6671123,274.626696 C76.2310681,271.29157 76.9604024,266.61315 80.295742,264.17753 Z M84.5952315,270.064676 C84.5112494,270.126004 84.4929651,270.243291 84.5546686,270.327768 L89.9723121,277.749978 C90.0332237,277.833436 90.1491902,277.851522 90.2328699,277.790388 L162.749637,224.824874 C162.833343,224.763747 162.851628,224.64646 162.789924,224.561983 L157.372281,217.139773 C157.311369,217.056316 157.195402,217.038229 157.111723,217.099363 L84.5952315,270.064676 Z"
                        id="Stroke-1"
                        fillRule="nonzero"
                        transform="translate(123.672305, 247.444876) rotate(-54.000000) translate(-123.672305, -247.444876) "
                    ></path>
                    <path
                        d="M11.8256118,175.467529 L114.952629,175.467529 C119.060524,175.467529 122.38991,178.797814 122.38991,182.905833 L122.38991,190.239181 C122.38991,194.347201 119.060524,197.677486 114.952629,197.677486 L11.8256118,197.677486 C7.71816804,197.677486 4.38833108,194.34709 4.38833108,190.239181 L4.38833108,182.905833 C4.38833108,178.797924 7.71816804,175.467529 11.8256118,175.467529 Z M11.8256118,182.757529 C11.7445975,182.757529 11.6783311,182.823806 11.6783311,182.905833 L11.6783311,190.239181 C11.6783311,190.321208 11.7445975,190.387486 11.8256118,190.387486 L114.952629,190.387486 C115.033928,190.387486 115.09991,190.321485 115.09991,190.239181 L115.09991,182.905833 C115.09991,182.823529 115.033928,182.757529 114.952629,182.757529 L11.8256118,182.757529 Z"
                        id="Stroke-3"
                        fillRule="nonzero"
                        transform="translate(63.389121, 186.572507) rotate(4.000000) translate(-63.389121, -186.572507) "
                    ></path>
                    <path
                        d="M105.199927,3.75607966 L36.3241373,3.75607966 C24.113611,3.75607966 14.2177513,12.7802697 14.2177513,23.8423432 L14.2177513,145.086576 C14.2177513,143.182905 15.9760671,141.556641 17.9606285,141.556641 C18.2531197,141.556641 18.5495408,141.591458 18.8465232,141.666144 C27.0665934,143.485583 33.1319969,150.254006 33.1319969,158.331976 C33.1319969,148.797894 41.5917864,141.156814 51.9670846,141.156814 C62.3423829,141.156814 70.8021724,148.797894 70.8021724,158.331976 C70.8021724,148.797894 79.2619618,141.156814 89.6366987,141.156814 C100.011997,141.156814 108.471786,148.797894 108.471786,158.331976 C108.471786,150.326447 114.457471,143.631026 122.597821,141.666144 C122.895927,141.593704 123.191786,141.559449 123.482032,141.559449 C125.531716,141.559449 127.306874,143.256468 127.306874,145.232581 L127.306874,23.8423432 C127.306874,12.7802697 117.410453,3.75607966 105.199927,3.75607966 M105.199927,11.0338334 C113.37733,11.0338334 120.031085,16.7791034 120.031085,23.8423432 L120.031085,134.814049 C115.514032,136.056771 111.562874,138.367008 108.46112,141.402775 C103.706593,136.7677 97.0247689,133.87906 89.6366987,133.87906 C82.2430145,133.87906 75.5566987,136.772192 70.8021724,141.413445 C66.0470846,136.772192 59.3607689,133.87906 51.9670846,133.87906 C44.5952952,133.87906 37.9269443,136.755345 33.1741022,141.37189 C30.0515759,138.322084 26.0611197,136.023639 21.4929794,134.820788 L21.4929794,23.8423432 C21.4929794,16.7791034 28.1467338,11.0338334 36.3241373,11.0338334 L105.199927,11.0338334"
                        id="Fill-5"
                        transform="translate(70.762313, 81.044028) rotate(4.000000) translate(-70.762313, -81.044028) "
                    ></path>
                    <path
                        d="M84.2455647,133.690596 C76.8518805,133.690596 70.1655647,136.583728 65.4104769,141.22498 C60.6553892,136.583728 53.9690734,133.690596 46.5753892,133.690596 C39.2041612,133.690596 32.5358103,136.566881 27.7829682,141.183987 C24.4358805,137.914613 20.0923015,135.508911 15.1087226,134.390293 C14.277284,134.191503 13.4239506,134.090423 12.5700559,134.090423 C6.59672256,134.090985 1.55026642,139.039408 1.55026642,144.898112 L1.55026642,170.005801 C1.55026642,176.947745 7.42759976,182.595866 14.6517401,182.595866 L116.169214,182.595866 C123.349003,182.595866 129.190968,176.980877 129.190968,170.078803 L129.190968,145.044116 C129.190968,139.006276 124.211319,134.093792 118.090898,134.093792 C117.216231,134.093792 116.33988,134.198803 115.487109,134.406579 C110.617494,135.581352 106.36374,137.987615 103.068863,141.213188 C98.314898,136.579797 91.6330734,133.690596 84.2455647,133.690596 M84.2455647,140.96835 C94.6203015,140.96835 103.080652,148.60943 103.080652,158.143512 C103.080652,150.137982 109.066337,143.442561 117.206687,141.47768 C117.504231,141.40524 117.800652,141.371546 118.090898,141.371546 C120.140582,141.371546 121.91574,143.068004 121.91574,145.044116 L121.91574,170.078803 C121.91574,172.989905 119.361354,175.318112 116.169214,175.318112 L14.6517401,175.318112 C11.4601612,175.318112 8.8260559,172.917464 8.8260559,170.005801 L8.8260559,144.898112 C8.8260559,142.99444 10.5849331,141.368177 12.5694945,141.368177 C12.8619857,141.368177 13.1584068,141.402993 13.4548278,141.47768 C21.6754594,143.297119 27.7408629,150.065542 27.7408629,158.143512 C27.7408629,148.60943 36.2006524,140.96835 46.5753892,140.96835 C56.9512489,140.96835 65.4110384,148.60943 65.4110384,158.143512 C65.4110384,148.60943 73.8708278,140.96835 84.2455647,140.96835"
                        id="Fill-8"
                        transform="translate(65.370617, 158.143231) rotate(4.000000) translate(-65.370617, -158.143231) "
                    ></path>
                    <path
                        d="M48.3138023,8.71540094 C70.3138023,32.3820676 84.9804689,60.5487343 92.3138023,93.2154009 C99.6471356,125.882068 104.618289,143.548734 107.227264,146.215401 L122.727264,139.215401 L122.727264,26.2154009 L118.227264,14.2154009 L95.8138023,8.71540094 L48.3138023,8.71540094 Z"
                        id="Path-2"
                        transform="translate(85.520533, 77.465401) rotate(4.000000) translate(-85.520533, -77.465401) "
                    ></path>
                    <path
                        d="M104.5,196.5 C106.833333,225.166667 108,247 108,262 C108,284.5 105.937186,279.293353 113.5,291.5 C121.062814,303.706647 111,282 116,275 C119.333333,270.333333 119.333333,256 116,232 L116,214.5 L116,200.5 L104.5,196.5 Z"
                        id="Path-3"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);
