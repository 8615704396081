import React from 'react';
import { Camera } from '../../../assets/svg/menuIcons/Camera';
import { DevIcon } from '../../../assets/svg/menuIcons/DevIcon';
import { GraphicIcon } from '../../../assets/svg/menuIcons/GraphicIcon';
import { HashTag } from '../../../assets/svg/menuIcons/HashTag';
import { PaintIcon } from '../../../assets/svg/menuIcons/PaintIcon';

export const MenuWhatWeDoIcons = () => (
    <React.Fragment>
        <HashTag />
        <GraphicIcon />
        <Camera />
        <PaintIcon />
        <DevIcon />
    </React.Fragment>
);
