import React from 'react';

export const DevIcon = ({ ...rest }) => (
    <svg
        {...rest}
        viewBox="0 0 70 70"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="UI-desk-menu-hvr2"
                transform="translate(-534.000000, -691.000000)"
            >
                <rect
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="1920"
                    height="1081"
                ></rect>
                <g
                    id="icons_main"
                    transform="translate(164.000000, 691.000000)"
                    fill="#000000"
                >
                    <g
                        id="icon/dev-s"
                        transform="translate(370.000000, 0.000000)"
                    >
                        <path
                            d="M25.524893,27.1226199 L25.524893,29.327254 L15.6538329,35.2460427 L25.524893,41.135008 L25.524893,43.3999771 L12.6,35.8500803 L12.6,34.7328516 L25.524893,27.1226199 Z M44.475107,27.1226428 L57.4,34.7328745 L57.4,35.8501032 L44.475107,43.4 L44.475107,41.135031 L54.3461671,35.2460656 L44.475107,29.3270475 L44.475107,27.1226428 Z M42.0589422,25.2 L29.9256274,43.0678596 L27.9462132,43.0678596 L40.0795279,25.2 L42.0589422,25.2 Z"
                            id="Combined-Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
