import { combineEpics } from 'redux-observable';
import { homeEffects } from './homeEffects';
import { whatWeDoEffects } from './whatWeDoEffects';
import { contactsEffects } from './contactEffects';
import { ourProjectsEffects } from './ourProjectsEffect';
import { cookiesEffects } from './cookiesEffects';
import { privacyEffects } from './privacyEffects';
import { error404Effects } from './error404Effect';
import { cookiesConfigEffects } from './cookiesConfigurationEffects';
import { jamenetReviewEffects } from './jamenetReviewEffects';

export default combineEpics(
    homeEffects,
    whatWeDoEffects,
    contactsEffects,
    ourProjectsEffects,
    cookiesEffects,
    privacyEffects,
    error404Effects,
    cookiesConfigEffects,
    jamenetReviewEffects
);
