import React from 'react';
import './FormErrorMessage.css';

export const FormErrorMessage = ({ message, mini }) => (
    <div
        className={`form-error-message ${
            mini ? 'form-error-message--smaller' : ''
        }`}
    >
        <span>{message}</span>
    </div>
);
