import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class GoogleMaps extends Component {
    static defaultProps = {
        center: {
            lat: 43.261885009662095,
            lng: -2.9437241595113486
        },
        zoom: 16
    };

    render() {
        const { lat, lng } = this.props;
        const latitude = lat ? lat : 43.261885009662095;
        const longitude = lng ? lng : -2.9437241595113486;
        const renderMarkers = (map, maps) => {
            let marker = new maps.Marker({
                position: { lat: latitude, lng: longitude },
                map,
                title: 'Jamenet'
            });
            return marker;
        };

        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY}}
                    defaultCenter={{ lat: latitude, lng: longitude }}
                    defaultZoom={this.props.zoom}
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMaps;