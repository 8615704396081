import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { STATE } from '../../../../constants/state';
import { t } from '../../../../constants/transations';
import {
    LETTERS_ONLY_MINUMUN_3_REGEX,
    SIMPLE_EMAIL_REGEX,
} from '../../../../constants/validationRegex';
import { FormCheckbox } from '../../../atoms/formCheckbox/FormCheckbox';
import { FormErrorMessage } from '../../../atoms/formErrorMessage/FormErrorMessage';
import { FormInput } from '../../../atoms/formInput/FormInput';
import { Button } from '../../../components/common/button/Button';

import './JamenetReviewForm.css';

export const JamenetReviewFormView = (props) => {
    const { formState, lang } = props;
    const [isValid, setIsValid] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [nameIsValid, setNameIsValid] = useState(false);
    const [surnameIsValid, setSurnameIsValid] = useState(false);
    const [data, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        privacy: false,
        newsletter: '',
    });

    useEffect(() => {
        setIsValid(
            data.privacy === true &&
                emailIsValid &&
                nameIsValid &&
                surnameIsValid
        );

        return () => {};
    }, [data, emailIsValid, nameIsValid, surnameIsValid]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            props.FetchJamenetReviewForm(STATE.DEFAULT);
        }
        return () => (isSubscribed = false);
    }, [props]);

    const submitForm = async (e) => {
        e.preventDefault();
        props.FetchJamenetReviewForm(STATE.SUBMITTING);
        const nameSurname = `${data.name} ${data.surname}`;
        await axios
            .post(
                process.env.REACT_APP_API_URL + `review-downloader`,
                {
                    name: nameSurname,
                    email: data.email,
                    newsletter: data.newsletter,
                    lang: lang,
                },
                {
                    headers: {
                        'accept-language': 'es',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.result &&
                    response.data.result === 'OK, saved'
                ) {
                    props.FetchJamenetReviewForm(STATE.SUCCESS);
                } else {
                    props.FetchJamenetReviewForm(STATE.ERROR);
                }
            })
            .catch((err) => {
                props.FetchJamenetReviewForm(STATE.ERROR);
            });
    };

    const validateFields = (name, value) => {
        if (name === 'email') {
            setEmailIsValid(SIMPLE_EMAIL_REGEX.test(value));
        }
        if (name === 'name') {
            setNameIsValid(LETTERS_ONLY_MINUMUN_3_REGEX.test(value));
        }
        if (name === 'surname') {
            setSurnameIsValid(LETTERS_ONLY_MINUMUN_3_REGEX.test(value));
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        validateFields(name, value);
        setFormData({
            ...data,
            [name]: value,
        });
    };

    const handleCheckboxChange = ({ name, value }) => {
        setFormData({
            ...data,
            [name]: value,
        });
    };

    return (
        <div className="JamenetReviewForm">
            <form onSubmit={(e) => submitForm(e)}>
                <FormInput
                    required
                    type="text"
                    placeholder={t.name[lang]}
                    name="name"
                    onChange={handleInputChange}
                />

                <FormInput
                    onChange={handleInputChange}
                    required
                    type="text"
                    placeholder={t.surname[lang]}
                    name="surname"
                />
                <FormInput
                    onChange={handleInputChange}
                    required
                    type="email"
                    placeholder={t.email[lang]}
                    name="email"
                />
                <FormCheckbox
                    value={data.privacy}
                    onChange={() =>
                        handleCheckboxChange({
                            name: 'privacy',
                            value: !data.privacy,
                        })
                    }
                    id="privacy"
                    name="privacy"
                    content={t.accept_provicy[lang]}
                />
                <FormCheckbox
                    value={data.newsletter}
                    onChange={() =>
                        handleCheckboxChange({
                            name: 'newsletter',
                            value: !data.newsletter,
                        })
                    }
                    id="newsletter"
                    name="newsletter"
                    content={t.newsletter_suscribe[lang]}
                />

                <Button
                    disabled={!isValid || formState === STATE.SUBMITTING}
                    className="button--primary"
                    type="submit"
                    value="Submit"
                >
                    {t.download[lang]}
                </Button>
                {formState === STATE.ERROR ? (
                    <FormErrorMessage message={t.generic_form_error[lang]} />
                ) : null}
            </form>
        </div>
    );
};
