import { bindActionCreators } from 'redux';
import {
    fetchOpenMenuModal,
    isActiveIcon,
    FetchJamenetReviewForm,
} from '../../../model/actions';

export const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchOpenMenuModal,
            isActiveIcon,
            FetchJamenetReviewForm,
        },
        dispatch
    );
