import { createSelector } from '@reduxjs/toolkit';

export const contactSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        const firstRow =
            mainState &&
            mainState.contactPayload &&
            mainState.contactPayload.contactInfo &&
            mainState.contactPayload.contactInfo.slice(0, 2);

        const rssRow =
            mainState &&
            mainState.contactPayload &&
            mainState.contactPayload.contactInfo &&
            mainState.contactPayload.contactInfo.slice(2, 3);

        return {
            contactInfo: firstRow,
            rrsBlock: rssRow,
            address:
                mainState &&
                mainState.contactPayload &&
                mainState.contactPayload.address,
            coordinates:
                mainState &&
                mainState.contactPayload &&
                mainState.contactPayload.coordinates,
            title:
                mainState &&
                mainState.contactPayload &&
                mainState.contactPayload.title,
        };
    }
);
