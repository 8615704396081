import { Redirect } from 'react-router';
import { ERROR_404, ERROR_500 } from '../../../../routing/routes';

export const ErrorRedirectView = (props) => {
    if (props.status === 404) {
        return <Redirect from={props.pathname} to={ERROR_404[props.lang]} />;
    }
    if (props.status === 500) {
        return <Redirect from={props.pathname} to={ERROR_500[props.lang]} />;
    }
    return props.component;
};
