import { createSelector } from "@reduxjs/toolkit";

export const whatWeDoSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            cards: mainState && mainState.whatWeDoPayload && mainState.whatWeDoPayload.cards,
            ourProjects: mainState && mainState.whatWeDoPayload && mainState.whatWeDoPayload.ourProjects,
            contact: mainState && mainState.whatWeDoPayload && mainState.whatWeDoPayload.contact,
            lang: mainState && mainState.lang,
            title: mainState && mainState.whatWeDoPayload && mainState.whatWeDoPayload.title
        }
    }
);