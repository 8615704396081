import React from 'react';
import { STATE } from '../../../../constants/state';
import { t } from '../../../../constants/transations';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { Footer } from '../../../components/common/footer';
import { Header } from '../../../components/common/header';
import { JamenetReviewForm } from '../../../forms/jamenetReviewForm';
import UseScreenType from 'react-screentype-hook';

import './JamenetReviewStyle.css';
import { JAMENET_REVIEW } from '../../../../routing/routes';

const ContentRight = ({ data, isMobile }) => (
    <div
        className={
            isMobile
                ? 'Container_container JamenetReviewView__content-right'
                : 'JamenetReviewView__content-right '
        }
    >
        <h2 className="JamenetReviewView__subtitle">{data.header}</h2>
        <div className="JamenetReviewView__content-form">
            <div className="JamenetReviewView__content">
                <p>{data.description}</p>
                <br />
                <p>
                    <strong>{data.formText}</strong>
                </p>
            </div>

            <JamenetReviewForm />
        </div>
    </div>
);

export const JamenetReviewView = (props) => {
    const { jmnetReviewIntro, formState, lang, magazine, title } = props;
    const screenType = UseScreenType({
        mobile: 400,
        tablet: 1023,
        desktop: 1200,
        largeDesktop: 1600,
    });

    const isMobile = screenType.isMobile || screenType.isTablet;

    return (
        <div>
            <Header page="jamenetReview" currentRoute={JAMENET_REVIEW} />
            <Container>
                <PageTitle title={title} />
            </Container>
            {formState === STATE.SUCCESS ? (
                <div className="JamenetReviewView__content-container--success">
                    <Container className="JamenetReviewView__magazine-grid">
                        <div className="JamenetReviewView__magazine-grid-left">
                            <img src={magazine.img} alt={magazine.alt} />
                        </div>
                        <div className="JamenetReviewView__magazine-grid-right">
                            <div>
                                <h2 className="JamenetReviewView__magazine-title">
                                    {magazine.title}
                                </h2>
                                <p>{magazine.date}</p>

                                <a
                                    className="button button--primary"
                                    href={magazine.url}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t.download[lang]}
                                </a>
                            </div>
                        </div>
                    </Container>
                </div>
            ) : jmnetReviewIntro ? (
                <div className="JamenetReviewView__content-container ">
                    <div className="JamenetReviewView__content-grid">
                        <div className="JamenetReviewView__content-magazine">
                            <img
                                src="/revista/review02.jpg"
                                alt={t.magazine_alt[lang]}
                            />
                        </div>
                        <ContentRight
                            data={jmnetReviewIntro}
                            isMobile={isMobile}
                        />
                    </div>
                </div>
            ) : null}
            <Footer />
        </div>
    );
};
