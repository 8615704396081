import { createSelector } from '@reduxjs/toolkit';

export const headerSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            lang: mainState && mainState.lang,
            headerLogo:
                mainState &&
                mainState.headerPayload &&
                mainState.headerPayload.headerLogo,
            headerIcon:
                mainState &&
                mainState.headerPayload &&
                mainState.headerPayload.headerIcon,
            menuModal: mainState && mainState.menuModal,
        };
    }
);
