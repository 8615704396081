import { connect } from 'react-redux';
import { jamenetReviewFormSelector } from './selector';
import { withRouter } from 'react-router';
import { mapDispatchToProps } from './action';
import { JamenetReviewFormView } from './View/JamenetReviewFormView';

export const JamenetReviewForm = withRouter(
    connect(
        jamenetReviewFormSelector,
        mapDispatchToProps
    )(JamenetReviewFormView)
);
