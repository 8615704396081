import {
    fetchApiCallError,
    fetchCookiesModalDataSuccess,
    fetchFooterDataSuccess,
    fetchHomeDataSuccess,
    fetchMenuDataSuccess,
} from '../actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { jamenetApiCall } from '../../api/apiCall';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { concat, of } from 'rxjs';
import { ERROR_404, HOME, INDEX } from '../../routing/routes';

const checkIsCorrectRoute = (url, lang) => url === HOME[lang] || url === INDEX;

const homePageActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('homepage?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchHomeDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const footerHomeActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('footer?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchFooterDataSuccess(response.response)),
                catchError((err) =>
                    concat(
                        of(fetchApiCallError(err)),
                        of(push(ERROR_404[$state.value.main.lang]))
                    )
                )
            )
        )
    );

const menuHomeActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'menumodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) => fetchMenuDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const CookiesModalHomeActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'cookiesmodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) =>
                    fetchCookiesModalDataSuccess(response.response)
                ),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

export const homeEffects = combineEpics(
    homePageActionEpic,
    footerHomeActionEpic,
    menuHomeActionEpic,
    CookiesModalHomeActionEpic
);
