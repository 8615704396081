import React from 'react';

export const HashTag = ({ ...rest }) => (
    <svg
        {...rest}
        viewBox="0 0 70 70"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="UI-desk-menu-hvr2"
                transform="translate(-164.000000, -691.000000)"
            >
                <rect
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="1920"
                    height="1081"
                ></rect>
                <g
                    id="icons_main"
                    transform="translate(164.000000, 691.000000)"
                    fill="#000000"
                >
                    <g id="icon/" transform="translate(19.600000, 16.800000)">
                        <path
                            d="M25.6001798,0 L22.9922448,10.7543159 L12.9330321,10.7543159 L15.5942888,0 L12.5074241,0 L9.89948898,10.7543159 L2.28848776,10.7543159 L2.28848776,13.4699864 L9.20751397,13.4699864 L6.70598091,23.9045135 L0,23.9045135 L0,26.779475 L6.01424718,26.779475 L3.29991008,37.8 L6.38677487,37.8 L9.04803159,26.779475 L19.1067617,26.779475 L16.3924246,37.8 L19.4797719,37.8 L22.1407874,26.779475 L29.9645926,26.779475 L29.9645926,23.9045135 L22.8327624,23.9045135 L25.3342954,13.4699864 L32.2,13.4699864 L32.2,10.7543159 L26.0260292,10.7543159 L28.6870446,0 L25.6001798,0 Z M11.9223461,13.23 L21.7823529,13.23 L19.3303585,23.625 L9.47058824,23.625 L11.9223461,13.23 Z"
                            id="Fill-1"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
