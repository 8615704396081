import { createSelector } from '@reduxjs/toolkit';

export const cookiesSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        var configurar;
        mainState.lang === 'es'
            ? (configurar = 'CONFIGURAR COOKIES')
            : (configurar = 'EZARRI COOKIAK');

        return {
            card1:
                mainState &&
                mainState.cookiesPayload &&
                mainState.cookiesPayload.cookies &&
                mainState.cookiesPayload.cookies.card1,
            card2:
                mainState &&
                mainState.cookiesPayload &&
                mainState.cookiesPayload.cookies &&
                mainState.cookiesPayload.cookies.card2,
            card3:
                mainState &&
                mainState.cookiesPayload &&
                mainState.cookiesPayload.cookies &&
                mainState.cookiesPayload.cookies.card3,
            table:
                mainState &&
                mainState.cookiesPayload &&
                mainState.cookiesPayload.cookies &&
                mainState.cookiesPayload.cookies.table1,
            lang: mainState && mainState.lang,

            confCookies: configurar,
        };
    }
);
