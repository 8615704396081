export const ModalView = (props) => (
    <div
        id={props && props.id}
        className={"modal " + props.isActive + " " + props.className}
    >
        {props.hasBackground &&
            <div
                className="modal-background"
                style={{backgroundColor: '#FFFFFF', opacity: 0.8}}
            />
        }
        <div>
            <div className={"modal-content modal-card " + props.contentClassname}>
                <section className={"modal-card-body " + props.bodyClassname}>
                    {props && props.children}
                </section>
            </div>
        </div>
    </div>
);