import { createSelector } from '@reduxjs/toolkit';

export const cookiesConfigSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            title:
                mainState &&
                mainState.cookiesConfigPayload &&
                mainState.cookiesConfigPayload.cookies &&
                mainState.cookiesConfigPayload.cookies.title,
            subtitle:
                mainState &&
                mainState.cookiesConfigPayload &&
                mainState.cookiesConfigPayload.cookies &&
                mainState.cookiesConfigPayload.cookies.subtitle,
            textBlock:
                mainState &&
                mainState.cookiesConfigPayload &&
                mainState.cookiesConfigPayload.cookies &&
                mainState.cookiesConfigPayload.cookies.textBlock,
            table:
                mainState &&
                mainState.cookiesConfigPayload &&
                mainState.cookiesConfigPayload.cookies &&
                mainState.cookiesConfigPayload.cookies.configTable,
            lang: mainState && mainState.lang,
        };
    }
);
