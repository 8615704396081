import React from 'react';
import './Button.css';

export const Button = ({ type, className, children, onClick, disabled }) => (
    <button
        disabled={disabled}
        className={`button ${className ? className : 'button--primary'}`}
        type={type ? type : 'button'}
        onClick={onClick}
    >
        {children}
    </button>
);
