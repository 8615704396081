import { Header } from '../../../components/common/header';
import { Footer } from '../../../components/common/footer';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { ContactCard } from '../../../components/contactCard';
import { WhatWeDoCards } from '../../../components/whatWeDoCards';
import { LinkWithArrow } from '../../../atoms/linkWithArrow/LinkWithArrow';
import { OUR_PROJECTS, WHAT_WE_DO } from '../../../../routing/routes';
import './WhatWeDoView.css';

export const WhatWeDoPageView = (props) => (
    <>
        <Header page="whatWeDo" currentRoute={WHAT_WE_DO} />
        <Container>
            <PageTitle title={props && props.title} />
        </Container>
        {props.cards && <WhatWeDoCards cards={props.cards} />}
        <Container>
            <LinkWithArrow
                link={OUR_PROJECTS[props.lang]}
                text={props.ourProjects}
            />
        </Container>
        <Container>
            <ContactCard data={props.contact} className={'space-between'} />
        </Container>
        <Footer />
    </>
);
