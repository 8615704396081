import React from 'react';
import { Link } from 'react-router-dom';
import CrossImage from '../../../../assets/svg/close.svg';
import { Container } from '../../../atoms/container/Container';
import { JamenetDots } from '../../../atoms/jamenetDots/JamenetDots';
import { MenuWhatWeDoIcons } from '../../../atoms/menuWhatWeDoIcons/MenuWhatWeDoIcons';
import { MenuSocialButtons } from '../../../atoms/menuSocialButtons.js/MenuSocialButtons';
import { LangSelector } from '../../../atoms/langSelector/LangSelector';

import './MenuPageView.css';

const MenuItems = ({ links, lang }) => (
    <ul className="menu-ul">
        {links &&
            links.length &&
            links.map((item, key) => (
                <Link key={key} to={lang === 'es' ? item.slugEs : item.slugEu}>
                    <li>{lang === 'es' ? item.pageNameEs : item.pageNameEu}</li>
                </Link>
            ))}
    </ul>
);

const MenuCloseButton = ({ action, props }) => (
    <div className="pull-cross-img">
        <LangSelector props={props} />
        <div onClick={() => action(false)}>
            <img className="cross-img" src={CrossImage} alt="cross" />
        </div>
    </div>
);

const MenuWhatWeDoLogos = ({ icons }) => (
    <div className="menu-logos">
        {icons && icons.length && icons.length > 2 ? (
            icons.map((icon, key) => (
                <img
                    className={'menu-icon'}
                    key={key}
                    src={icon.icon}
                    alt={icon.title}
                />
            ))
        ) : (
            <MenuWhatWeDoIcons />
        )}
    </div>
);

const ModalBg = ({ isActive, img, icons }) => (
    <div
        id="modal-fadeInScale-fs"
        className={
            'modal modal-full-screen modal-fx-fadeInScale modal-opacity ' +
            isActive
        }
    >
        <div className="modal-content modal-card">
            <Container className="modal-card-body modal-card-body-bg">
                <img src={img} alt="" />
                <MenuWhatWeDoLogos icons={icons} />
            </Container>
        </div>
    </div>
);

export const MenuPageView = (props) => (
    <React.Fragment>
        <ModalBg
            isActive={props.isActive}
            img={props.image}
            icons={props.icons}
        />
        <div
            id="modal-fadeInScale-fs"
            className={
                'modal modal-full-screen modal-fx-fadeInScale modal-opacity ' +
                props.isActive
            }
        >
            <div className="modal-content modal-card">
                <section className="modal-card-body">
                    <Container noPaddingBottom>
                        <MenuCloseButton
                            props={props}
                            action={props.fetchOpenMenuModal}
                        />
                    </Container>
                    <Container className="modal-card__content">
                        <MenuItems links={props.menuLinks} lang={props.lang} />
                        <div className="modal-card-body__jamenet-dots">
                            <JamenetDots className="menu-brand-icon" />
                        </div>
                        <MenuSocialButtons lang={props.lang} />
                    </Container>
                </section>
            </div>
        </div>
    </React.Fragment>
);
