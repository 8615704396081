import { Header } from '../../../components/common/header';
import { ErrorFooter } from '../../../components/common/errorFooter';
import error404Image from '../../../../assets/svg/icon-jmnt-404.svg';
import { Container } from '../../../atoms/container/Container';
import './Error404View.css';
import { ERROR_404 } from '../../../../routing/routes';

export const Error404View = () => (
    <>
        <Header page="error404" currentRoute={ERROR_404} />
        <Container className="error404">
            <div className="error404__title">
                <p>Error 404</p>
            </div>
            <div className="error404__content">
                <div className="error404__content-subtitle">
                    <p>
                        Hemen ez dago ezer ikusteko//{' '}
                        <span className="error404__content-subtitle-es">
                            No hay nada que ver por aquí
                        </span>
                    </p>
                </div>
                <img
                    className="error404__content-img"
                    src={error404Image}
                    alt="404"
                />
            </div>
        </Container>
        <ErrorFooter />
    </>
);
