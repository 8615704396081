import { CoreAction } from '../actions';
import { getBasedLang } from '../../utils/basedLangFuction';
import { detectMob } from '../../utils/isMobileDevice';
import { STATE } from '../../constants/state';

export const mainPayload = (state = {}, action) => {
    const googleCookiesAcceptance =
        localStorage && localStorage.getItem('GoogleCookiesAcceptance');
    switch (action.type) {
        case CoreAction.FetchHomeDataSuccess:
            return {
                ...state,
                homePayload: action.payload,
                title: action.payload.title,
            };
        case CoreAction.FetchContactDataSuccess:
            return {
                ...state,
                contactPayload: action.payload,
                title: action.payload.title,
            };
        case CoreAction.FetchFooterDataSuccess:
            return {
                ...state,
                footerPayload: action.payload,
            };
        case CoreAction.FetchOurProjectsDataSuccess:
            return {
                ...state,
                ourProjectsPayload: action.payload,
                title: action.payload.title,
            };
        case CoreAction.FetchWhatWeDoDataSuccess:
            return {
                ...state,
                whatWeDoPayload: action.payload,
                title: action.payload.title,
            };
        case CoreAction.FetchOpenMenuModal:
            return {
                ...state,
                menuModal: action.payload,
            };
        case CoreAction.FetchCookiesDataSuccess:
            return {
                ...state,
                cookiesPayload: action.payload,
            };
        case CoreAction.FetchPrivacyDataSuccess:
            return {
                ...state,
                privacyPayload: action.payload,
                title: action.payload.title,
            };
        case CoreAction.FetchMenuDataSuccess:
            return {
                ...state,
                menuPayload: action.payload,
            };
        case CoreAction.FetchHeaderDataSuccess:
            return {
                ...state,
                headerPayload: action.payload,
            };
        case CoreAction.FetchOpenCookiesModal:
            return {
                ...state,
                cookiesModal: action.payload,
            };
        case CoreAction.FetchCookiesModalDataSuccess:
            return {
                ...state,
                cookiesModalPayload: action.payload,
            };
        case CoreAction.FetchCookiesConfigurationDataSuccess:
            return {
                ...state,
                cookiesConfigPayload: action.payload,
            };
        case CoreAction.FetchJamenetReviewDataSuccess:
            return {
                ...state,
                jamenetReviewPayload: action.payload,
                title: 'Jamenet Review',
            };
        case CoreAction.FetchJamenetReviewForm:
            return {
                ...state,
                jamenetReviewFormPayload: action.payload,
                jamenetReviewFormState: action.payload,
                title: 'Jamenet Review',
            };
        case CoreAction.IsActiveIcon:
            return {
                ...state,
                isActiveIcon: action.payload,
            };
        default:
            return {
                ...state,
                lang: getBasedLang(),
                menuModal: false,
                cookiesModal:
                    googleCookiesAcceptance === null ||
                    googleCookiesAcceptance === '0',
                title: getBasedLang() === 'es' ? 'Inicio' : 'Hasiera',
                isMobileDevice: detectMob(),
                isActiveIcon: false,
                jamenetReviewFormState: STATE.DEFAULT,
            };
    }
};
