import { CoreAction } from "../actions";

export const errorReducer = (state={}, action) => {
    switch (action.type) {
        case CoreAction.FetchApiCallError:
            return {
                ...state,
                errorApiCall: action.payload
            }
        default:
            return state
    }
}