import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../assets/svg/Arrow';
import './LinkWithArrow.css';

export const LinkWithArrow = ({ link, text, className }) => (
    <Link to={link} className={classNames('linkWithArrow', className)}>
        <Arrow className="linkWithArrow__arrow" />
        <p className="linkWithArrow__message">{text}</p>
    </Link>
);
