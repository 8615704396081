import classNames from 'classnames';
import React from 'react';
import './Container.css';

export const Container = ({
    className,
    children,
    noLeftMargin,
    noPaddingBottom,
}) => {
    return (
        <div
            className={classNames('Container_container', className, {
                'Container_container--no-left-margin': noLeftMargin,
                'Container_container--no-bottom-padding': noPaddingBottom,
            })}
        >
            {children}
        </div>
    );
};
