import React from 'react';
import './ContactCardStyle.css';

export const ContactCardView = ({ data, className }) => (
    <div className={'contactCard ' + className}>
        {data &&
            data.length &&
            data.map((contact, key) => (
                <div className={'contactCard-column'} key={key}>
                    <h2 className="contactCard-title">{contact.title}</h2>
                    <p className="contactCard-content">{contact.description}</p>
                    <div>
                        {contact.phone && (
                            <div
                                className="contactCard-content"
                                dangerouslySetInnerHTML={{
                                    __html: contact.phone,
                                }}
                            />
                        )}
                        <div
                            className="contactCard-content"
                            dangerouslySetInnerHTML={{
                                __html: contact.email,
                            }}
                        />
                    </div>
                </div>
            ))}
    </div>
);
