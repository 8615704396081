import { Footer } from '../../../components/common/footer';
import { MenuPage } from '../../menuPage';
import { CookiesModal } from '../../cookiesModal';
import { Menu } from '../../../../assets/svg/menu';
import { Link } from 'react-router-dom';
import { Container } from '../../../atoms/container/Container';
import { CONTACT, HOME } from '../../../../routing/routes';
import './HomePageStyle.css';

export const HomePageView = (props) => {
    return (
        <>
            <CookiesModal />
            <MenuPage page="home" currentRoute={HOME} />
            <div
                style={{
                    backgroundImage: `url(${props && props.backgroundImage})`,
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100%',
                }}
                className="fill-height"
            >
                <Container className="home__header">
                    {props && !props.menuModal ? (
                        <div className="burger-img-position">
                            <Menu
                                className="is-burger-img"
                                onClick={() => props.fetchOpenMenuModal(true)}
                            />
                        </div>
                    ) : null}
                </Container>

                <section className="section">
                    <div className="columns">
                        <div className="column is-1" />
                        <div className="column menus-container">
                            {props && props.logo ? (
                                <img
                                    className="home-logo"
                                    src={props && props.logo}
                                    alt="logo"
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-1" />
                        <div className="column menus-container">
                            <h2 className="subtitle-text">
                                {props && props.description}
                            </h2>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-1" />
                        <div className="column menus-container">
                            <Link
                                className="is-home-button button"
                                to={CONTACT[props && props.lang]}
                            >
                                {props &&
                                    props.button &&
                                    props.button.text.toUpperCase()}
                            </Link>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-1" />
                        <div className="column menus-container">
                            <div className="logo-box">
                                {props &&
                                    props.icons &&
                                    props.icons.map((icon, key) => (
                                        <img
                                            className={
                                                props.isLinkActive
                                                    ? 'home-icons-active'
                                                    : 'home-icons'
                                            }
                                            key={key}
                                            src={icon.icon}
                                            alt={icon.title}
                                            onMouseOver={() =>
                                                props.isActiveIcon(true)
                                            }
                                            onMouseOut={() =>
                                                props.isActiveIcon(false)
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};
