import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../constants/transations';
import { COOKIES } from '../../../routing/routes';

export const CookiesLink = ({ lang, className }) => (
    <Link to={COOKIES[lang]} className={className}>
        {t.cookies[lang]}
    </Link>
);
