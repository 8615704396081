import { createSelector } from '@reduxjs/toolkit';

export const jamenetReviewFormSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            formState: mainState.jamenetReviewFormState,
            ...mainState,
        };
    }
);
