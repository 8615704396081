import React from 'react';
import { langSelected } from '../../../utils/detectLangMenu';

export const LangSelector = ({ props }) => {
    const { lang, currentRoute } = props;
    return (
        <div id="langSelector">
            <a href={currentRoute['eu']}>{langSelected(lang, 'EU')}</a> |{' '}
            <a href={currentRoute['es']}>{langSelected(lang, 'ES')}</a>
        </div>
    );
};
