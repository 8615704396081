import { createSelector } from '@reduxjs/toolkit';

export const menuSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        const isActive = mainState && mainState.menuModal ? 'is-active' : '';
        return {
            image:
                mainState &&
                mainState.menuPayload &&
                mainState.menuPayload.image,
            menuLinks:
                mainState &&
                mainState.menuPayload &&
                mainState.menuPayload.items &&
                mainState.menuPayload.items.items,
            icons:
                mainState &&
                mainState.menuPayload &&
                mainState.menuPayload.icons,
            isActive,
            lang: mainState && mainState.lang,
        };
    }
);
