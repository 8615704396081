import { Header } from '../../../components/common/header';
import { Footer } from '../../../components/common/footer';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import './CookiesConfigView.css';
import { COOKIES_CONFIG } from '../../../../routing/routes';

export const CookiesConfigView = (props) => (
    <>
        <Header page="cookies" currentRoute={COOKIES_CONFIG} />
        <Container noPaddingBottom>
            <PageTitle title={props && props.title} smaller />
        </Container>
        <Container noPaddingBottom>
            <div className="cookies-config-subtitle">
                <p>{props && props.subtitle}</p>
            </div>
        </Container>
        <Container>
            <div
                className="cookies-card"
                dangerouslySetInnerHTML={{
                    __html: props && props.textBlock,
                }}
            />
            <div className="column">
                <div className="table-container">
                    <div
                        className="cookies-table"
                        dangerouslySetInnerHTML={{
                            __html: props.table,
                        }}
                    />
                </div>
            </div>
        </Container>
        <Footer />
    </>
);
