import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore, { history } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { CustomHead } from './modules/components/common/customHead';
import { HelmetProvider } from 'react-helmet-async';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <HelmetProvider>
            <ConnectedRouter history={history}>
                <React.StrictMode>
                    <CustomHead />
                    <App />
                </React.StrictMode>
            </ConnectedRouter>
        </HelmetProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
