export const CoreAction = {
    FetchHomeDataSuccess: 'core/FETCH_HOME_DATA_SUCCESS',
    FetchWhatWeDoDataSuccess: 'core/FETCH_WHAT_WE_DO_DATA_SUCCESS',
    FetchOurProjectsDataSuccess: 'core/FETCH_OUR_PROJECTS_DATA_SUCCESS',
    FetchFooterDataSuccess: 'core/FETCH_FOOTER_DATA_SUCCESS',
    FetchSocialMediaDataSuccess: 'core/FETCH_SOCIAL_MEDIA_DATA_SUCCESS',
    FetchContactDataSuccess: 'core/FETCH_CONTACT_DATA_SUCCESS',
    FetchOpenMenuModal: 'core/FETCH_OPEN_MENU_MODAL',
    FetchApiCallError: 'core/FETCH_API_CALL_ERROR',
    FetchCookiesDataSuccess: 'core/FETCH_COOKIES_DATA_SUCCESS',
    FetchPrivacyDataSuccess: 'core/FETCH_PRIVACY_DATA_SUCCESS',
    FetchMenuDataSuccess: 'core/FETCH_MENU_DATA_SUCCESS',
    FetchHeaderDataSuccess: 'core/FETCH_HEADER_DATA_SUCCESS',
    FetchOpenCookiesModal: 'core/FETCH_OPEN_COOKIES_MODAL',
    FetchCookiesModalDataSuccess: 'core/FETCH_COOKIES_MODAL_DATA_SUCCESS',
    FetchCookiesConfigurationDataSuccess:
        'core/FETCH_COOKIES_CONFIGURATION_DATA_SUCCESS',
    FetchJamenetReviewDataSuccess: 'core/FETCH_JAMENET_REVIEW_SUCCESS',
    FetchJamenetReviewForm: 'core/FETCH_JAMENET_REVIEW_FORM',
    // fetchJamenetReviewFormSubmitting:
    //     'core/FETCH_JAMENET_REVIEW_FORM_SUBMITTING',
    // fetchJamenetReviewFormSuccess: 'core/FETCH_JAMENET_REVIEW_FORM_SUCCESS',
    // fetchJamenetReviewFormError: 'core/FETCH_JAMENET_REVIEW_FORM_ERROR',
    // fetchJamenetReviewFormReset: 'core/FETCH_JAMENET_REVIEW_FORM_RESET',
    IsActiveIcon: 'core/IS_ACTIVE_ICON',
};

export const fetchHomeDataSuccess = (payload) => ({
    type: CoreAction.FetchHomeDataSuccess,
    payload,
});

export const fetchWhatWeDoDataSuccess = (payload) => ({
    type: CoreAction.FetchWhatWeDoDataSuccess,
    payload,
});

export const fetchOurProjectsDataSuccess = (payload) => ({
    type: CoreAction.FetchOurProjectsDataSuccess,
    payload,
});

export const fetchFooterDataSuccess = (payload) => ({
    type: CoreAction.FetchFooterDataSuccess,
    payload,
});

export const fetchSocialMediaDataSuccess = (payload) => ({
    type: CoreAction.FetchSocialMediaDataSuccess,
    payload,
});

export const fetchContactDataSuccess = (payload) => ({
    type: CoreAction.FetchContactDataSuccess,
    payload,
});

export const fetchOpenMenuModal = (payload) => ({
    type: CoreAction.FetchOpenMenuModal,
    payload,
});

export const fetchApiCallError = (payload) => ({
    type: CoreAction.FetchApiCallError,
    payload,
});

export const fetchCookiesDataSuccess = (payload) => ({
    type: CoreAction.FetchCookiesDataSuccess,
    payload,
});

export const fetchPrivacyDataSuccess = (payload) => ({
    type: CoreAction.FetchPrivacyDataSuccess,
    payload,
});

export const fetchMenuDataSuccess = (payload) => ({
    type: CoreAction.FetchMenuDataSuccess,
    payload,
});

export const fetchHeaderDataSuccess = (payload) => ({
    type: CoreAction.FetchHeaderDataSuccess,
    payload,
});

export const fetchOpenCookiesModal = (payload) => ({
    type: CoreAction.FetchOpenCookiesModal,
    payload,
});

export const fetchCookiesModalDataSuccess = (payload) => ({
    type: CoreAction.FetchCookiesModalDataSuccess,
    payload,
});

export const fetchCookiesConfigurationDataSuccess = (payload) => ({
    type: CoreAction.FetchCookiesConfigurationDataSuccess,
    payload,
});

export const isActiveIcon = (payload) => ({
    type: CoreAction.IsActiveIcon,
    payload,
});

export const FetchJamenetReviewDataSuccess = (payload) => ({
    type: CoreAction.FetchJamenetReviewDataSuccess,
    payload,
});

export const FetchJamenetReviewForm = (payload) => ({
    type: CoreAction.FetchJamenetReviewForm,
    payload,
});
