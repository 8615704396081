import './HeaderView.css';
import { MenuPage } from '../../../../layout/menuPage';
import { Link } from 'react-router-dom';
import menuLogo from '../../../../../assets/img/logo_header.png';
import menuBurger from '../../../../../assets/svg/menu.svg';
import { Container } from '../../../../atoms/container/Container';
import { HOME } from '../../../../../routing/routes';

export const HeaderView = (props) => (
    <header className="header">
        <Container className="Container__header Container__special-padding">
            <MenuPage props={props} currentRoute={props.currentRoute} />

            <Link to={HOME[props.lang]}>
                <img
                    className="header__logo"
                    src={menuLogo}
                    alt="burger_menu"
                />
            </Link>

            {props && !props.menuModal ? (
                <img
                    className="header__menu_icon"
                    src={
                        props && props.headerIcon
                            ? props.headerIcon.icon
                            : menuBurger
                    }
                    alt="burger_menu"
                    onClick={() => props.fetchOpenMenuModal(true)}
                />
            ) : null}
        </Container>
    </header>
);
