import { createSelector } from "@reduxjs/toolkit";

export const privacySelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            content: mainState && mainState.privacyPayload && mainState.privacyPayload.privacyPolicy
                && mainState.privacyPayload.privacyPolicy.content,
            lang: mainState && mainState.lang
        }
    }
);
