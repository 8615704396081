import { Redirect, Route, Switch, withRouter } from 'react-router';
import { HomePage } from './modules/layout/homePage';
import { ContactsPage } from './modules/layout/contactPage';
import { connect } from 'react-redux';
import { OurProjectsPage } from './modules/layout/ourProjectsPage';
import { WhatWeDoPage } from './modules/layout/whatWeDoPage';
import { PrivacyPage } from './modules/layout/privacyPage';
import { ScrollToTop } from './modules/components/common/scrollToTop';
import { Error404 } from './modules/layout/err404';
import { Error500 } from './modules/layout/err500';
import { CookiesPage } from './modules/layout/cookiesPage';
import { ErrorRedirect } from './modules/components/errorRedirect';
import { CookiesConfig } from './modules/layout/cookiesConfig';
import { JamenetReview } from './modules/layout/jamenetReview';
import { BODY_OVERFLOW_HIDDEN } from './constants/style';
import { useEffect } from 'react';
import {
    CONTACT,
    COOKIES,
    COOKIES_CONFIG,
    ERROR_404,
    ERROR_500,
    HOME,
    INDEX,
    JAMENET_REVIEW,
    OUR_PROJECTS,
    PRIVACY,
    WHAT_WE_DO,
} from './routing/routes';

const App = (props) => {
    useEffect(() => {
        props.menuOpen
            ? document.body.classList.add(BODY_OVERFLOW_HIDDEN)
            : document.body.classList.remove(BODY_OVERFLOW_HIDDEN);
    }, [props]);
    // console.log('%c Made by Jamenet - www.jamenet.eus', 'padding:30px;');

    return (
        <div className={`App`}>
            <ScrollToTop>
                <Switch>
                    <Route
                        exact
                        path={INDEX}
                        render={() => <Redirect to={HOME[props.lang]} />}
                    />
                    <Route
                        exact
                        strict
                        path={HOME[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<HomePage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={CONTACT[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<ContactsPage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={OUR_PROJECTS[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<OurProjectsPage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={WHAT_WE_DO[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<WhatWeDoPage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={COOKIES[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<CookiesPage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={PRIVACY[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<PrivacyPage />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={COOKIES_CONFIG[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<CookiesConfig />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={JAMENET_REVIEW[props.lang]}
                        render={() => (
                            <ErrorRedirect
                                status={props.status}
                                location={props.location.pathname}
                                component={<JamenetReview />}
                                lang={props.lang}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ERROR_404[props.lang]}
                        component={Error404}
                    />
                    <Route
                        exact
                        path={ERROR_500[props.lang]}
                        component={Error500}
                    />
                    <Route component={Error404} />
                </Switch>
            </ScrollToTop>
        </div>
    );
};

const mapStateToProps = (state) => ({
    lang: state && state.main ? state.main.lang : 'es',
    status:
        state &&
        state.error &&
        state.error.errorApiCall &&
        state.error.errorApiCall.status,
    menuOpen: state && state.main ? state.main.menuModal : false,
});

export default withRouter(connect(mapStateToProps, null)(App));
