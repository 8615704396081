import { applyMiddleware, createStore } from "redux";
import { rootReducer } from '../model/reducers';
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from 'redux-devtools-extension';
import effects from '../model/epics';
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

const initialState = {};

const composeEnhancers = composeWithDevTools({
    name: 'kpi-coe'
});

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const middlewares = [
    routerMiddleware(history),
    epicMiddleware
];

export default function configureStore() {
    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares),
        )
    );

    epicMiddleware.run(effects);

    return store;
}