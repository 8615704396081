import {
    fetchApiCallError,
    fetchCookiesDataSuccess,
    fetchFooterDataSuccess,
    fetchHeaderDataSuccess,
    fetchMenuDataSuccess,
} from '../actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { jamenetApiCall } from '../../api/apiCall';
import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { COOKIES } from '../../routing/routes';

const checkIsCorrectRoute = (url, lang) => url === COOKIES[lang];

const CookiesPageActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('cookies?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchCookiesDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const footerCookiesActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('footer?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchFooterDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const menuCookiesActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'menumodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) => fetchMenuDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const headerCookiesActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('header?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchHeaderDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

export const cookiesEffects = combineEpics(
    CookiesPageActionEpic,
    footerCookiesActionEpic,
    menuCookiesActionEpic,
    headerCookiesActionEpic
);
