import React from 'react';
import { SocialButtonInstagram } from '../socialButtonInstagram/SocialButtonInstagram';
import { SocialButtonLinkedin } from '../socialButtonLinkedin/SocialButtonLinkedin';
import { SocialButtonTwitter } from '../socialButtonTwitter/SocialButtonTwitter';

import './MenuSocialButtons.css';

export const MenuSocialButtons = ({ lang }) => (
    <div className="menu-social-buttons">
        <SocialButtonInstagram className="menu-social-icon" lang={lang} />
        <SocialButtonLinkedin className="menu-social-icon" lang={lang} />
        <SocialButtonTwitter className="menu-social-icon" lang={lang} />
    </div>
);
