export const INDEX = '/';

export const HOME = {
    es: '/es',
    eu: '/eu',
};

export const WHAT_WE_DO = {
    es: '/es/que-hacemos',
    eu: '/eu/zer-egiten-dugu',
};

export const OUR_PROJECTS = {
    es: '/es/nuestros-proyectos',
    eu: '/eu/gure-proiektuak',
};

export const CONTACT = {
    es: '/es/contactos',
    eu: '/eu/kontaktuak',
};

export const COOKIES = {
    es: '/es/cookies',
    eu: '/eu/cookies',
};

export const PRIVACY = {
    es: '/es/privacidad',
    eu: '/eu/pribatasuna',
};

export const ERROR_404 = {
    es: '/es/error-404',
    eu: '/eu/404-akatsa',
};

export const ERROR_500 = {
    es: '/es/error-500',
    eu: '/eu/500-akatsa',
};

export const COOKIES_CONFIG = {
    es: '/es/cookies-configuracion',
    eu: '/eu/cookies-konfigurazioa',
};

export const JAMENET_REVIEW = {
    es: '/es/jamenet-review',
    eu: '/eu/jamenet-review',
};
