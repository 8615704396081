import React from 'react';
import { Link } from 'react-router-dom';
import { INSTAGRAM } from '../../../constants/rrss';
import { t } from '../../../constants/transations';
import instagramLogo from './../../../assets/svg/social/instagram-b.svg';
import instagramLogoWhite from './../../../assets/svg/social/instagram.svg';
import './SocialButtonInstagram.css';

export const SocialButtonInstagram = ({ className, white, lang }) => (
    <Link
        to={{ pathname: INSTAGRAM }}
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            className={className}
            src={white ? instagramLogoWhite : instagramLogo}
            alt={t.instagram_alt[lang]}
        />
    </Link>
);
