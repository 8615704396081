import { createSelector } from "@reduxjs/toolkit";

export const ourProjectsSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            projects: mainState && mainState.ourProjectsPayload && mainState.ourProjectsPayload.projects,
            contacts: mainState && mainState.ourProjectsPayload && mainState.ourProjectsPayload.contact,
            title: mainState && mainState.ourProjectsPayload && mainState.ourProjectsPayload.title
        }
    }
);
