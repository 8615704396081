import React from 'react';
import { Link } from 'react-router-dom';
import { LINKEDIN } from '../../../constants/rrss';
import { t } from '../../../constants/transations';
import linkedinLogo from './../../../assets/svg/social/linkedin-b.svg';
import linkedinLogoWhite from './../../../assets/svg/social/linkedin.svg';

import './SocialButtonLinkedin.css';

export const SocialButtonLinkedin = ({ className, white, lang = 'es' }) => (
    <Link
        to={{
            pathname: LINKEDIN,
        }}
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            className={className}
            src={white ? linkedinLogoWhite : linkedinLogo}
            alt={t.linkedin_alt[lang]}
        />
    </Link>
);
