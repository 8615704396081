import React from 'react';

export const GraphicIcon = ({ ...rest }) => (
    <svg
        {...rest}
        viewBox="0 0 70 70"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="UI-desk-menu-hvr2"
                transform="translate(-256.000000, -691.000000)"
            >
                <rect
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="1920"
                    height="1081"
                ></rect>
                <g
                    id="icons_main"
                    transform="translate(164.000000, 691.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="icon/markt-s"
                        transform="translate(92.000000, 0.000000)"
                    >
                        <polygon
                            id="Stroke-2"
                            points="45.8765749 12.0721408 39.4500909 50.620287 31.4121559 29.5343895 27.8763674 41.2068172 19.1817828 25.8189628 12.9841884 50.9165748 15.0158116 51.4182631 19.9507567 31.4338418 28.4609349 46.49533 31.6388952 36.0041731 40.2446812 58.579713 46.4217135 21.5278592 53.0549367 44.5634936 56.9770202 34.3304731 55.0229798 33.5815339 53.3369888 37.9804152"
                        ></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
