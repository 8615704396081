import React from 'react';

export const Menu = ({ ...rest }) => (
    <svg
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        {...rest}
    >
        <g
            id="icon/nav/menu"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M1.5,15 L48.5,15 C49.3284271,15 50,15.6715729 50,16.5 C50,17.3284271 49.3284271,18 48.5,18 L1.5,18 C0.671572875,18 1.01453063e-16,17.3284271 0,16.5 C-1.01453063e-16,15.6715729 0.671572875,15 1.5,15 Z M1.5,24 L48.5,24 C49.3284271,24 50,24.6715729 50,25.5 C50,26.3284271 49.3284271,27 48.5,27 L1.5,27 C0.671572875,27 1.01453063e-16,26.3284271 0,25.5 C-1.01453063e-16,24.6715729 0.671572875,24 1.5,24 Z M1.5,33 L48.5,33 C49.3284271,33 50,33.6715729 50,34.5 C50,35.3284271 49.3284271,36 48.5,36 L1.5,36 C0.671572875,36 1.01453063e-16,35.3284271 0,34.5 C-1.01453063e-16,33.6715729 0.671572875,33 1.5,33 Z"
                id="Combined-Shape"
                fill="#000000"
            ></path>
        </g>
    </svg>
);
