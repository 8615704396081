import {
    fetchApiCallError,
    fetchCookiesConfigurationDataSuccess,
    fetchFooterDataSuccess,
    fetchMenuDataSuccess,
} from '../actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { jamenetApiCall } from '../../api/apiCall';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { concat, of } from 'rxjs';
import { COOKIES_CONFIG, ERROR_404 } from '../../routing/routes';

const checkIsCorrectRoute = (url, lang) => url === COOKIES_CONFIG[lang];

const CookiesConfigPageActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'cookiesconfigpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) =>
                    fetchCookiesConfigurationDataSuccess(response.response)
                ),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const footerCookiesConfigActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('footer?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchFooterDataSuccess(response.response)),
                catchError((err) =>
                    concat(
                        of(fetchApiCallError(err)),
                        of(push(ERROR_404[$state.value.main.lang]))
                    )
                )
            )
        )
    );

const menuCookiesConfigActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'menumodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) => fetchMenuDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

export const cookiesConfigEffects = combineEpics(
    CookiesConfigPageActionEpic,
    footerCookiesConfigActionEpic,
    menuCookiesConfigActionEpic
);
