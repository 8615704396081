import {
    fetchApiCallError,
    fetchFooterDataSuccess,
    fetchHeaderDataSuccess,
    fetchMenuDataSuccess,
    fetchOurProjectsDataSuccess,
} from '../actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { jamenetApiCall } from '../../api/apiCall';
import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { OUR_PROJECTS } from '../../routing/routes';

const checkIsCorrectRoute = (url, lang) => url === OUR_PROJECTS[lang];

const ourProjectsPageEffect = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('ourprojects?_lang=' + $state.value.main.lang).pipe(
                map((response) =>
                    fetchOurProjectsDataSuccess(response.response)
                ),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const footerOurProjectsActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('footer?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchFooterDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const menuOurProjectsActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'menumodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) => fetchMenuDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const headerOurProjectsActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('header?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchHeaderDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

export const ourProjectsEffects = combineEpics(
    ourProjectsPageEffect,
    footerOurProjectsActionEpic,
    menuOurProjectsActionEpic,
    headerOurProjectsActionEpic
);
