import React from 'react';
import './GalleryStyle.css';

export const GalleryView = ({ items }) => (
    <div className="gallery">
        {items &&
            items.length &&
            items.map((item, key) => (
                <div className="gallery__img-container" key={key}>
                    <img
                        key={'logo_' + key}
                        src={item.logo}
                        alt={item.name}
                        className="gallery__img-container-logo"
                    />
                    <img
                        key={'bg_' + key}
                        src={item.background}
                        alt={item.name}
                        className="gallery__img-container-bg"
                    />
                </div>
            ))}
    </div>
);
