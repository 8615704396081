import {combineReducers} from "redux";
import { mainPayload } from "./mainReducer";
import {connectRouter} from "connected-react-router";
import { errorReducer } from "./errorReducer";

export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    main: mainPayload,
    error: errorReducer
});
