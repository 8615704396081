import { Footer } from '../../../components/common/footer';
import { Header } from '../../../components/common/header';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { Gallery } from '../../../components/gallery';
import { ContactCard } from '../../../components/contactCard';
import './OurProjectsPage.css';
import { OUR_PROJECTS } from '../../../../routing/routes';

export const OurProjectsPageView = (props) => (
    <>
        <Header page="projects" currentRoute={OUR_PROJECTS} />
        <Container>
            <PageTitle title={props && props.title} />
        </Container>
        {props.projects && <Gallery items={props.projects} />}
        {props.contacts && (
            <Container>
                <ContactCard data={props.contacts} />
            </Container>
        )}
        <Footer />
    </>
);
