import { ajax } from 'rxjs/ajax';

export const jamenetApiCall = (url) => {
    return ajax.getJSON(process.env.REACT_APP_API_URL + 'page/' + url);
};

// const getLocation = (action) => {
//     const path = action.payload.location.pathname;
//     return path;
// };
