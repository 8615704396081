import { Header } from '../../../components/common/header';
import { ErrorFooter } from '../../../components/common/errorFooter';
import { Container } from '../../../atoms/container/Container';
import { Error500icon } from '../../../../assets/svg/Error500icon';
import './Error500View.css';
import { ERROR_500 } from '../../../../routing/routes';

export const Error500View = () => (
    <>
        <Header page="error500" currentRoute={ERROR_500} />
        <Container className="error500">
            <div className="error500__title">
                <p>Error 500</p>
            </div>
            <div className="error500__content">
                <div className="error500__content-subtitle">
                    <p>Zerbait gaizki dabil…// </p>
                    <p className="error500__content-subtitle-es">
                        Algo ha ido mal…
                    </p>
                </div>
                <Error500icon className="error500__content-icon" />
            </div>
        </Container>
        <ErrorFooter />
    </>
);
