import './WhatWeDoCardStyle.css';

export const WhatWeDoCardView = (props) => {
    const { icon, title, color, field1, field2, field3 } = props;
    const fields = [field1, field2, field3];
    return (
        <div className="whatWeDoCard">
            <div className="whatwedocard__top">
                {icon && icon.icon ? (
                    <img
                        className="whatwedocard__logo"
                        src={icon.icon}
                        alt={`${title && title} logo`}
                    />
                ) : null}
                <h2 className="whatwedocard__title">{title && title}</h2>
            </div>
            <div className="whatwedocard__bottom">
                <div className="whatwedocard__dot-container">
                    <div
                        className="whatwedocard__dot"
                        style={{ backgroundColor: color && color }}
                    />
                </div>
                <div className="whatwedocard__description">
                    {fields &&
                        fields.length &&
                        fields.map((field, i) =>
                            field ? <p key={i}>{field}</p> : null
                        )}
                </div>
            </div>
        </div>
    );
};
