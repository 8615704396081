import React from 'react';

export const Arrow = ({ ...rest }) => (
    <svg
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        {...rest}
    >
        <g
            id="icon/nav/go-to"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M36.2002477,23.4999898 L29.31235,15.3987619 C28.7732179,14.7646597 28.8460601,13.8100114 29.4750474,13.2664952 C30.1040348,12.722979 31.0509826,12.7964135 31.5901146,13.4305157 L40.5900942,24.0158769 C41.0715799,24.5821782 41.0715799,25.4178218 40.5900942,25.9841231 L31.5901146,36.5694843 C31.0509826,37.2035865 30.1040348,37.277021 29.4750474,36.7335048 C28.8460601,36.1899886 28.7732179,35.2353403 29.31235,34.6012381 L36.2002651,26.4999898 L11.5121945,26.4999898 C10.6770325,26.4999898 10,25.8284116 10,24.9999898 C10,24.1715612 10.6770325,23.4999898 11.5121945,23.4999898 L36.2002477,23.4999898 Z"
                id="Combined-Shape"
                fill="#000000"
                fillRule="nonzero"
            ></path>
        </g>
    </svg>
);
