import React from 'react';
import './FormInput.css';

export const FormInput = ({ onChange, placeholder, type, name, required }) => (
    <input
        className="FormInput"
        onChange={onChange}
        required={required}
        type={type}
        placeholder={required ? `${placeholder}*` : placeholder}
        name={name}
    />
);
