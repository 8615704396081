/**
 * Function to know in wich language is browsing and mark it
 * @param {String} lang Language browsing
 * @param {String} text Text to show (Language)
 * @returns The posibilities
 */

export function langSelected(lang, text) {
    if (text.toLowerCase() === lang) {
        return <b>{text}</b>;
    } else {
        return text;
    }
}
