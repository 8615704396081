import React from 'react';
import classNames from 'classnames';
import { Check } from '../../../assets/svg/Check';
import './FormCheckbox.css';

export const FormCheckbox = ({
    className,
    label,
    inputClassName,
    content,
    ...rest
}) => (
    <label className={classNames('form-checkbox', className)}>
        <input
            className={classNames('form-checkbox__input', inputClassName)}
            type="checkbox"
            {...rest}
        />
        <div className="form-checkbox__box">
            <Check className="form-checkbox__check" />
        </div>
        <span
            className="form-checkbox__content"
            dangerouslySetInnerHTML={{ __html: content }}
        />
    </label>
);
