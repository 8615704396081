import {
    fetchApiCallError,
    fetchFooterDataSuccess,
    fetchHeaderDataSuccess,
    fetchMenuDataSuccess,
    fetchWhatWeDoDataSuccess,
} from '../actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { jamenetApiCall } from '../../api/apiCall';
import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { WHAT_WE_DO } from '../../routing/routes';

const checkIsCorrectRoute = (url, lang) => url === WHAT_WE_DO[lang];

const whatWeDoPageEffect = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('whatwedo?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchWhatWeDoDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const footerWhatWeDoActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('footer?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchFooterDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const menuWhatWeDoActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall(
                'menumodalpage?_lang=' + $state.value.main.lang
            ).pipe(
                map((response) => fetchMenuDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

const headerWhatWeDoActionEpic = ($action, $state) =>
    $action.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        filter((url) => checkIsCorrectRoute(url, $state.value.main.lang)),
        mergeMap(() =>
            jamenetApiCall('header?_lang=' + $state.value.main.lang).pipe(
                map((response) => fetchHeaderDataSuccess(response.response)),
                catchError((err) => of(fetchApiCallError(err)))
            )
        )
    );

export const whatWeDoEffects = combineEpics(
    whatWeDoPageEffect,
    footerWhatWeDoActionEpic,
    menuWhatWeDoActionEpic,
    headerWhatWeDoActionEpic
);
