import { createSelector } from "@reduxjs/toolkit";

export const cookiesModalSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        const isActive = mainState && mainState.cookiesModal ? 'is-active' : '';
        return {
            subtitle: mainState && mainState.cookiesModalPayload && mainState.cookiesModalPayload.subtitle,
            content: mainState && mainState.cookiesModalPayload && mainState.cookiesModalPayload.content,
            logo: mainState && mainState.cookiesModalPayload && mainState.cookiesModalPayload.image,
            button1Text: mainState && mainState.cookiesModalPayload && mainState.cookiesModalPayload.buttons[0] &&
                mainState.cookiesModalPayload.buttons[0].text,
            button2Text: mainState && mainState.cookiesModalPayload && mainState.cookiesModalPayload.buttons[0] &&
                mainState.cookiesModalPayload.buttons[1].text,
            isActive,
            lang: mainState && mainState.lang
        }
    }
);