import { Header } from '../../../components/common/header';
import { Footer } from '../../../components/common/footer';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { t } from '../../../../constants/transations';

import './PrivacyPageVIew.css';
import { PRIVACY } from '../../../../routing/routes';

export const PrivacyPageView = (props) => (
    <>
        <Header page="privacy" currentRoute={PRIVACY} />
        <Container noPaddingBottom>
            <PageTitle title={t.privacy_policy[props && props.lang]} smaller />
        </Container>
        <Container className="PrivacyPage__content">
            <div
                className="privacy-card"
                dangerouslySetInnerHTML={{ __html: props.content }}
            />
        </Container>
        <Footer />
    </>
);
