import { COOKIES_CONFIG, HOME } from '../../../../routing/routes';
import { Modal } from '../../../components/modal';
import './CookiesModalView.css';

const acceptOnClick = (props) => {
    localStorage.setItem('GoogleCookiesAcceptance', '1');
    props.fetchOpenCookiesModal(false);
};

export const CookiesModalView = (props) => {
    return (
        <Modal
            id="cookies-Modal"
            isActive={props && props.isActive}
            hasBackground={true}
            contentClassname="cookies-modal-div"
            bodyClassname="cookies-modal-body"
        >
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <img
                            className="cookies-modal-logo"
                            src={props.logo}
                            alt="cookie-logo"
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="cookies-modal-subtitle">
                            {props.subtitle}
                        </h1>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div
                            className="cookies-modal-content"
                            dangerouslySetInnerHTML={{ __html: props.content }}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column cookies-buttons-group">
                        <a
                            className="configuration-cookies-button button"
                            href={COOKIES_CONFIG[props && props.lang]}
                            onClick={() => props.fetchOpenCookiesModal(false)}
                        >
                            {props.button1Text}
                        </a>
                        <a
                            className="accepted-cookies-button button"
                            onClick={() => acceptOnClick(props)}
                            href={HOME[props.lang]}
                        >
                            {props.button2Text}
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
