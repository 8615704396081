import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../constants/transations';
import { PRIVACY } from '../../../routing/routes';

export const PrivacyLink = ({ lang, className }) => (
    <Link to={PRIVACY[lang]} className={className}>
        {t.privacy_policy[lang]}
    </Link>
);
