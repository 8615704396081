import { Header } from '../../../components/common/header';
import { Footer } from '../../../components/common/footer';
import './CookiesPageView.css';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { Container } from '../../../atoms/container/Container';
import { Link } from 'react-router-dom';
import { COOKIES, COOKIES_CONFIG } from '../../../../routing/routes';

export const CookiesPageView = (props) => (
    <div className="cookiesPage">
        <Header page="cookies" currentRoute={COOKIES} />
        <Container noPaddingBottom>
            <PageTitle title={'Cookies'} smaller />
        </Container>
        <Container className="cookiesPage__container">
            <div
                className="cookies-card1"
                dangerouslySetInnerHTML={{ __html: props.card1 }}
            />
        </Container>
        {props.card2 ? (
            <Container className="cookiesPage__container">
                <div
                    className="cookies-card2"
                    dangerouslySetInnerHTML={{ __html: props.card2 }}
                />
            </Container>
        ) : null}
        {props.card3 ? (
            <Container className="cookiesPage__container">
                <div
                    className="cookies-card3"
                    dangerouslySetInnerHTML={{ __html: props.card3 }}
                />
            </Container>
        ) : null}
        {props.table ? (
            <Container className="cookiesPage__container">
                <div
                    className="cookies-table"
                    dangerouslySetInnerHTML={{ __html: props.table }}
                />
            </Container>
        ) : null}
        <Container className="cookiesPage__container">
            <div className="column cookies-button-position">
                <Link
                    to={COOKIES_CONFIG[props && props.lang]}
                    className="is-black is-cookies-button button button-primary"
                >
                    {props.confCookies}
                </Link>
            </div>
        </Container>

        <Footer />
    </div>
);
