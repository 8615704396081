import { Footer } from '../../../components/common/footer';
import { Header } from '../../../components/common/header';
import GoogleMaps from '../../../components/googleMaps/view/GoogleMapsView';
import { Link } from 'react-router-dom';
import { Container } from '../../../atoms/container/Container';
import { PageTitle } from '../../../atoms/pageTitle/PageTitle';
import { ContactCard } from '../../../components/contactCard';
import './ContactPageView.css';
import { CONTACT } from '../../../../routing/routes';

export const ContactPageView = (props) => (
    <>
        <Header page="contact" currentRoute={CONTACT} />
        <Container>
            <PageTitle title={props && props.title} />
        </Container>
        <Container>
            <ContactCard data={props.contactInfo} className={'space-between'} />
        </Container>
        <Container noPaddingBottom>
            <div className="ContactPage__rrss">
                {props &&
                    props.rrsBlock &&
                    props.rrsBlock.map((info, key) => (
                        <div className="ContactPage__rrss-row" key={key}>
                            <p className="contact-subtitle--rss">
                                {info.description}
                            </p>
                            {info.socialMedia &&
                                info.socialMedia.map((item, i) => (
                                    <Link
                                        key={i}
                                        to={{ pathname: item.link }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="contact__link"
                                    >
                                        <p className="contact-subtitle--gray">
                                            <img
                                                className="contact-rss-icon"
                                                src={
                                                    item.icon && item.icon.icon
                                                }
                                                alt={item.userName}
                                            />
                                            {item.userName}
                                        </p>
                                    </Link>
                                ))}
                        </div>
                    ))}
            </div>
        </Container>

        <Container>
            <div className="ContactPage__visit-row">
                <p className="contact-subtitle--rss">
                    {props && props.address}
                </p>
            </div>
        </Container>
        <div className="contact-google-map-box">
            <GoogleMaps
                lat={props.coordinates && props.coordinates.latitude}
                lng={props.coordinates && props.coordinates.longitude}
            />
        </div>
        <Footer />
    </>
);
