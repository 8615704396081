import { createSelector } from "@reduxjs/toolkit";

export const footerSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        const footerState = mainState && mainState.footerPayload;
        return {
            block1: footerState && footerState.block1,
            block2: footerState && footerState.block2,
            block3: footerState && footerState.block3,
            lang: mainState && mainState.lang,
            instagramLink: footerState && footerState.rssIcons && footerState.rssIcons.find(x => x.name === 'Instagram').link,
            linkedinLink: footerState && footerState.rssIcons && footerState.rssIcons.find(x => x.name === 'Linkedin').link,
            twitterLink: footerState && footerState.rssIcons && footerState.rssIcons.find(x => x.name === 'Twitter').link
        }
    }
);