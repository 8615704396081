import React from 'react';
import { Link } from 'react-router-dom';
import twitterLogo from './../../../assets/svg/social/twitterBlack.png';
import twitterLogoWhite from './../../../assets/svg/social/twitter.png';

import './SocialButtonTwitter.css';
import { t } from '../../../constants/transations';
import { TWITTER } from '../../../constants/rrss';

export const SocialButtonTwitter = ({ className, white, lang = 'es' }) => (
    <Link to={{ pathname: TWITTER }} target="_blank" rel="noopener noreferrer">
        <img
            className={className}
            src={white ? twitterLogoWhite : twitterLogo}
            alt={t.twitter_alt[lang]}
        />
    </Link>
);
