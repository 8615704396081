import React from 'react';
import { Container } from '../../../atoms/container/Container';
import { WhatWeDoCard } from '../../whatWeDoCard';
import './WhatWeDoCardsStyle.css';

export const WhatWeDoCardsView = ({ cards }) => (
    <Container className="whatWeDoCards__container">
        {cards &&
            cards.length &&
            cards.map((card, key) => (
                <div key={key}>
                    <WhatWeDoCard
                        icon={card && card.icon}
                        title={card && card.title}
                        field1={card && card.field1}
                        field2={card && card.field2}
                        field3={card && card.field3}
                        color={card && card.color}
                    />
                </div>
            ))}
    </Container>
);
